



import React from "react";
import _ from "lodash";
import PropTypes from "prop-types";
import Body from "./typography/body";

const colors = {
  blue: "#1F1BFD",
  purple: "#EB1BFD",
  orange: "#FDE71B",
  green: "#1BFD6A",
};

const getColorByName = (colorName: string) => {
  const color = colors[colorName];
  return color || "#FDE71B";
}

const Component = ({ text, colorName }) => {
  return (<div className="flex items-center gap-2" key={text}>
		<div className="rounded-[50px] bg-[#222] flex items-center px-3 py-1 gap-1" style={{ boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 1px 6px -1px rgba(255, 255, 255, 0.05)" }}>
			<div className="w-[12px] h-[12px] rounded-[12px]" style={{ background: getColorByName(colorName) }} />
			<Body as="span" variant="small" className="text-white">{text}</Body>
		</div>
	</div>);
};

Component.propTypes = {
  text: PropTypes.string.isRequired,
  colorName: PropTypes.string.isRequired,
};

export default Component;
